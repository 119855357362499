<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" max-width="400" @keypress.esc="dialog = false" scrollable>
      <v-card class="mx-auto">
        <v-card-title class="headline">
          รายการจุดตรวจ
          <v-spacer></v-spacer>
          <v-btn text color="red" @click.native="dialog = false">ปิด</v-btn>
        </v-card-title>
        <v-list two-line dense>
          <v-list-item v-for="c in checkpoints" :key="c.id">
            <v-list-item-icon>
              <v-icon>
                mdi-traffic-cone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ c.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ c.address }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    color="blue darken-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="duplicateCheckpoint(c.id)"
                  >
                    <v-icon small>
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                </template>
                <span>คัดลอกจุดตรวจนี้</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { listExists, duplicate } from '@/api/checkpoint'
export default {
  methods: {
    async getList() {
      this.checkpoints = await listExists()
    },
    duplicateCheckpoint(id) {
      duplicate(this.$route.params.id, id)
        .then(res => {
          if (res.success) {
            this.$emit('duplicated')
          }
        })
        .catch(err => {
          this.$emit('not-duplicate', err)
        })
    },
  },
  data() {
    return {
      checkpoints: [],
    }
  },
  created() {
    this.getList()
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
