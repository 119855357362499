<template>
  <v-form v-model="formValid" ref="form" lazy-validation :readonly="commandId != null">
    <v-text-field
      label="คำสั่งที่"
      v-model="command.plan_number"
      :rules="reqRule"
      readonly
      @keyup="genSlash"
      required
    ></v-text-field>

    <!-- planDate -->
    <v-menu
      v-model="datemenu"
      disabled
      ref="datemenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormat"
          :rules="reqRule"
          label="ลงวันที่"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker readonly v-model="command.plan_date" @input="datemenu = false" no-title> </v-date-picker>
    </v-menu>
    <!-- end planDate -->

    <v-text-field readonly label="เรื่อง" v-model="command.name" :rules="reqRule" required></v-text-field>

    <v-text-field
      name="onwer"
      label="ผู้ออกคำสั่ง"
      readonly
      v-model="command.owner"
      :loading="station_admins.length == 0"
    ></v-text-field>

    <!-- DateRange -->
    <v-menu
      v-model="dateRangemenu"
      ref="dateRangemenu"
      disabled
      :nudge-right="40"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeFormat"
          :rules="reqRule"
          label="มีผลในช่วงวันที่"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker range v-model="command.dates" no-title> </v-date-picker>
    </v-menu>
    <!-- endDateRange -->

    <v-text-field readonly label="หมายเหตุ/บันทึกอื่นๆ" v-model="command.remark" required></v-text-field>
  </v-form>
</template>

<script>
import { admin } from '@/api/officer'
export default {
  methods: {
    // util
    genSlash(e) {
      if (e.key === '/') {
        this.command.plan_number += this.moment()
          .add(543, 'year')
          .format('YYYY')
      }
    },
    async getAdmin() {
      const res = await admin()
      this.station_admins = res.map(m => ({ id: m.id, name: m.full_name }))
    },
    setStartAndEnd(v) {
      this.command.start_at = v[0]
      this.command.end_at = v[1]
    },
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
  },
  computed: {
    dateFormat() {
      return this.moment(this.command.plan_date)
        .add(543, 'year')
        .format('D MMMYYYY')
    },
    dateRangeFormat() {
      const start = this.moment(this.command.dates === undefined ? null : this.command.dates[0])
        .add(543, 'year')
        .format('D MMMYYYY')
      const end = this.moment(this.command.dates === undefined ? null : this.command.dates[1])
        .add(543, 'year')
        .format('D MMMYYYY')
      return `${start} ถึง ${end}`
    },
    formValid: {
      get() {
        return this.valid
      },
      set(v) {
        this.$emit('update:valid', v)
      },
    },

    command: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  created() {
    this.getAdmin()
  },
  data() {
    return {
      commandId: null,
      station_admins: [],
      datemenu: false,
      dateRangemenu: false,
      reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    }
  },
  watch: {},

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'add',
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style></style>
