var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lists,"options":_vm.options,"items-per-page":_vm.tableMeta.per_page,"server-items-length":_vm.tableMeta.total,"footer-props":_vm.footer,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)}},scopedSlots:_vm._u([{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.start_at))+" ")]}},{key:"item.end_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.end_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"blue darken-4","icon":"","x-small":"","dark":"","fab":""},slot:"activator"},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","color":"grey","dark":""},on:{"click":function($event){return _vm.logs(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-playlist-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Change Logs")])])],1)]}}],null,true),model:{value:(_vm.itemSelected),callback:function ($$v) {_vm.itemSelected=$$v},expression:"itemSelected"}}),_c('bjp-confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }