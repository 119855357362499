import http from './http'

const mainUri = '/api/v2/command'

export function list(params) {
  return http({
    url: `${mainUri}`,
    method: 'get',
    params: params,
  })
}

export function show(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'get',
  })
}

export function duplicate(id) {
  return http({
    url: `${mainUri}/${id}/duplicate`,
    method: 'post',
  })
}

export function getPdf(id) {
  return http({
    url: `${mainUri}/${id}/pdf`,
    method: 'get',
  })
}

export function getDocx(id) {
  return http({
    url: `${mainUri}/${id}/docxn`,
    method: 'get',
  })
}

export function store(data) {
  return http({
    url: `${mainUri}`,
    method: 'post',
    data: data,
  })
}

export function update(data) {
  return http({
    url: `${mainUri}/${data.id}`,
    method: 'patch',
    data: data,
  })
}

export function destroy(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'delete',
  })
}
