<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="headline">บันทึกข้อมูลการอนุมัติ</v-card-title>
      <v-container fluid>
        <v-form v-model="formValid" ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-radio-group v-model="approvalData.approval">
                <v-radio v-for="(item, i) in approvalItems" :key="i" :label="item.text" :value="item.value"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                name="reason"
                label="เหตุผล"
                hint="ถ้าไม่อนุมัติกรุณากรอกเหตุผล"
                v-model="approvalData.reason"
                :disabled="approvalData.approval == 1"
                :rules="approvalData.approval != 1 ? reqRule : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field name="remark" label="บันทึกอื่นๆ" v-model="approvalData.remark"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.prevent.stop="dialog = false">ปิด</v-btn>
        <v-btn color="primary" @click.prevent.stop="save">บันทึก</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { update } from '@/api/command_approval'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  methods: {
    open(id) {
      this.dialog = true
      this.approvalData.id = id
    },
    save() {
      if (!this.$refs.form.validate()) return
      update(this.approvalData)
        .then(res => {
          if (res.success) {
            this.getCommandApproval()
            this.$toast.success({
              title: 'Information',
              message: 'ทำรายการเรียบร้อยแล้ว กำลังปิดหน้า...',
            })
            setTimeout(() => {
              this.$router.push({ name: 'CommandApproval' })
            }, 3000)
          }
          console.log(res)
        })
        .catch(err => {
          this.$toast.error({
            title: 'Error',
            message: 'เกิดข้อผิดพลาดขึ้นระหว่างทำรายการ',
          })
          console.log(err)
        })
        .finally(() => {
          this.dialog = false
        })
    },
    ...mapActions('Appfilter', ['getCommandApproval']),
  },
  data() {
    return {
      dialog: false,
      approvalData: {
        id: '',
        approval: 0,
        reason: '',
        remark: '',
      },
      approvalItems: [
        { value: 1, text: 'อนุมัติ' },
        { value: 2, text: 'ไม่อนุมัติ' },
      ],
      reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
      formValid: true,
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['commandApproval']),
  },
}
</script>

<style></style>
