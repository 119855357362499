<template>
  <v-form v-model="formValid" ref="form" lazy-validation>
    <!-- name -->
    <v-row class="pb-0">
      <v-col>
        <v-select
          label="ประเภท (เลือกได้หลายแบบ)"
          v-model="checkpoint.checkpoint_types"
          :items="CheckpointTypes"
          multiple
          :rules="reqRule"
          required
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="ชื่อจุดตรวจ"
          hint="จุดตรวจหน้า... จุดตรวจแยก..."
          v-model="checkpoint.name"
          :rules="reqRule"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pb-0">
      <v-col>
        <!-- DateRange -->
        <v-menu
          v-model="datemenu"
          ref="datemenu"
          :nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormat"
              :rules="type == 'add' ? reqArrRule : reqRule"
              :label="type == 'add' ? 'วันที่(เลือกได้หลายวัน)' : 'วันที่'"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :multiple="type == 'add' ? true : false"
            :min="min"
            :max="max"
            :show-current="min"
            v-model="checkpoint.start_at"
            no-title
          >
            <v-spacer></v-spacer>
            <v-btn @click="datemenu = false" text>ตกลง</v-btn>
          </v-date-picker>
        </v-menu>
        <!-- endDateRange -->
      </v-col>
      <!-- times -->
      <v-col>
        <v-text-field-simplemask
          v-model="checkpoint.times"
          label="ช่วงเวลา"
          :properties="{
            prefix: '',
            suffix: '',
            rules: timeRules,
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            placeholder: '00:00-00:00',
          }"
          :options="{
            inputMask: '##:##-##:##',
            outputMask: '##:##-##:##',
            empty: '',
            applyAfter: false,
            alphanumeric: true,
            lowerCase: false,
          }"
        />
      </v-col>
    </v-row>
    <!-- officers -->
    <v-row class="pb-0">
      <v-col cols="12" md="3">
        <v-select label="เลือกชุด" :disabled="groups.length == 0" :items="groups" v-model="groupSelected"></v-select>
      </v-col>
      <v-col cols="12" md="9">
        <v-autocomplete
          label="เจ้าหน้าที่"
          :loading="officers.length == 0"
          :items="officers"
          v-model="officerSelected"
          :rules="reqArrRule"
          :return-object="false"
          multiple
          clearable
        ></v-autocomplete>
        <!-- <v-select
          label="เจ้าหน้าที่"
          :loading="officers.length == 0"
          :items="officers"
          v-model="officerSelected"
          :rules="reqArrRule"
          multiple
        ></v-select> -->
      </v-col>
    </v-row>
    <v-row class="pa-0" no-gutters>
      <v-col>
        <span class="red--text subtitle-2" v-show="!hasChief">* เลือกหัวหน้าจุดตรวจ</span>
        <v-simple-table dense>
          <tbody>
            <tr v-for="(off, i) in officerSelectedObject" :key="`off-${off.officer_id}`">
              <td>{{ officerInfo(off.officer_id) }}</td>
              <td>
                <v-select label="ทำหน้าที่" :items="officerTypes" v-model="off.officer_type_id"></v-select>
              </td>
              <td>
                <v-text-field label="หมายเหตุ" v-model="off.remark"></v-text-field>
              </td>
              <td>
                <v-icon small @click.prevent.stop="removeOfficer(i)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { selectBox, types as officerType } from '@/api/officer'
import { types as cpType } from '@/api/checkpoint'
import { list as listGroup, show as showGroup } from '@/api/officergroup'
export default {
  methods: {
    validate() {
      return this.$refs.form.validate() && this.cpChiefSelected()
    },
    // ตรวจสอบว่าเลือกหัวหน้าด่านหรือยัง
    cpChiefSelected() {
      const hasChief = this.officerSelectedObject.find(o => o.officer_type_id == 1)
      //   console.log(hasChief == undefined)
      this.hasChief = hasChief != undefined
      return hasChief
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    // ใช้ค้นหาชื่อ จนท.
    officerInfo(id) {
      const officer = this.officers.find(o => o.value == id)
      return officer === undefined ? '' : officer.text
    },
    removeOfficer(i) {
      this.officerSelected.splice(i, 1)
      this.officerSelectedObject.splice(i, 1)
    },

    getOfficerType() {
      officerType().then(res => {
        this.officerTypes = res.map(o => ({ value: o.id, text: o.name }))
      })
    },
    getCheckpointType() {
      cpType().then(res => {
        this.CheckpointTypes = res.map(o => ({
          value: o.id,
          text: o.name,
        }))
      })
    },
  },
  data: () => ({
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    hasChief: true,
    reqArrRule: [v => v.length != 0 || 'จำเป็นต้องกรอกข้อมูล'],
    CheckpointTypes: [],
    groups: [],
    groupsModel: [],
    groupSelected: null,
    officers: [],
    officerSelectedData: [],
    officerTypes: [],
    datemenu: false,
    times: '',
    timeRules: [
      v =>
        /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?)-(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?,\s?)?)+$/.test(
          v
        ) || 'กรอกเวลาให้ถูกต้อง',
    ],
  }),
  computed: {
    dateFormat() {
      if (Array.isArray(this.checkpoint.start_at)) {
        return this.checkpoint.start_at
          .map(d =>
            this.moment(d)
              .add(543, 'year')
              .format('D MMM')
          )
          .join(',')
      }
      return (
        this.moment(this.checkpoint.start_at)
          .add(543, 'year')
          .format('D MMMYYYY') || null
      )
    },
    formValid: {
      get() {
        return this.valid
      },
      set(v) {
        this.$emit('update:valid', v)
      },
    },
    dates: {
      get() {
        return [this.checkpoint.start_at, this.checkpoint.end_at]
      },
      set(v) {
        this.checkpoint.start_at = v[0]
        this.checkpoint.end_at = v[1]
      },
    },
    // ใช้สำหรับ v-select (selected items)
    // officerSelected: {
    //   get() {
    //     return this.officersObj.map(o => o.officer_id)
    //   },
    //   set(v) {
    //     this.officerSelectedObject = v.map(o => ({
    //       officer_id: o,
    //       officer_type_id: 2,
    //       remark: '',
    //     }))
    //   },
    // },
    officerSelected: {
      get() {
        // console.log('officerObj : ')
        // console.log(this.officersObj)
        return this.officersObj.map(o => o.officer_id)
      },
      set(v) {
        // console.log('selected: ')
        // console.log(v)
        this.officerSelectedObject = v.map(o => ({
          officer_id: o,
          officer_type_id: 2,
          remark: '',
        }))
      },
    },
    // ใช้สำหรับตาราง
    officerSelectedObject: {
      get() {
        return this.officersObj
      },
      set(v) {
        this.$emit('update:officersObj', v)
      },
    },
    checkpoint: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    min() {
      if (this.allowDates.length == 0) return undefined
      return this.allowDates[0]
    },
    max() {
      if (this.allowDates.length == 0) return undefined
      return this.allowDates[1]
    },
  },
  watch: {
    checkpoint() {
      this.resetValidation()
    },
    groupSelected(v) {
      if (v) {
        showGroup(v).then(res => {
          this.officerSelected = res.officers.map(o => o.id)
        })
      }
    },
  },
  async created() {
    const officers = await selectBox()
    const g = await listGroup()
    this.groups = g.map(g => ({
      text: g.name,
      value: g.id,
    }))
    if (officers) this.officers = officers
  },
  mounted() {
    this.getOfficerType()
    this.getCheckpointType()
  },
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'add',
    },
    allowDates: {
      type: Array,
      default: () => [],
    },
    // Object จนท ในด่านตรวจ (officer_id,officer_type,remark)
    officersObj: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style></style>
