<template>
  <v-dialog v-model="dialog" scrollable max-width="700px">
    <v-card elevation="3">
      <v-card-title>
        <v-icon>
          mdi-playlist-edit
        </v-icon>
        Change Logs
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px;">
        <v-list three-line>
          <v-list-item v-for="item in items" :key="item.id">
            <v-list-item-content>
              <v-list-item-title
                >{{ item.action }}
                <span class="subtitle-2 grey--text">
                  {{
                    moment(item.date)
                      .add(543, 'year')
                      .format('D MMMYYYY HH:MM')
                  }}
                </span></v-list-item-title
              >

              <li v-for="(log, i) in item.changed" :key="i">{{ log }}</li>

              <v-list-item-subtitle>
                {{ item.user }} {{ item.ip }} {{ item.browser }} {{ item.os }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click.native="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { activities as cpActivities } from '@/api/checkpoint'
import { activities as drpActivities } from '@/api/drunkreport'
import { activities as arpActivities } from '@/api/arrestreport'
export default {
  methods: {
    getLogs(model, id) {
      const fn = this.modelFn(model)
      fn(id).then(res => {
        // console.log(res)
        this.items = res
      })
    },
    modelFn(model) {
      switch (model) {
        case 'checkpoint':
          return cpActivities
        case 'drunkreport':
          return drpActivities
        case 'arrestreport':
          return arpActivities
        case 'command':
          return ''
      }
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
