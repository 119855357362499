<template>
  <v-container fluid>
    <v-toolbar dense rounded flat outlined class="mb-4">
      <v-breadcrumbs divider=">" :items="$route.meta.breadcrumbs"> </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="showApprovalDialog" :disabled="commandId == ''">
          <v-icon>mdi-check</v-icon>
          อนุมัติคำสั่ง
        </v-btn>
        <v-btn text @click="showPdf" :disabled="commandId == ''">
          <v-icon>mdi-printer</v-icon>
          พิมพ์คำสั่ง
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-row>
      <v-col cols="12" md="4" lg="3">
        <v-card :loading="loading">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                ข้อมูลคำสั่ง
                <span class="grey--text subtitle-2">
                  {{ type == 'edit' ? '(แก้ไข)' : '(เพิ่ม)' }}
                </span>
              </h3>
              <small>กรอกรายละเอียดให้ครบถ้วน</small>
            </div>
          </v-card-title>
          <v-container fluid>
            <cmd-form ref="cmdForm" :type="type" v-model="command" :valid.sync="valid"></cmd-form>
          </v-container>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <v-expand-transition>
          <checkpoint-list
            :command-id="commandId"
            :disabled="commandId == ''"
            :allowDates="[command.start_at, command.end_at]"
            :can="command.can"
          ></checkpoint-list>
        </v-expand-transition>
      </v-col>
    </v-row>
    <!-- Snack bar -->
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>

    <!-- confirm dlg -->
    <bjp-confirm-dialog ref="confirm" />

    <!-- approval dialog -->
    <approval-dialog ref="approvalDialog" />

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>
  </v-container>
</template>

<script>
import cmdForm from './form'
import { show, store, update } from '@/api/command_approval'
import { getPdf } from '@/api/command'
// components
import checkpointList from '../checkpoint'
import pdfDialog from '@/components/pdfDialog'
import approvalDialog from '../components/approvalDialog'
export default {
  name: 'CommandDetailPage',
  methods: {
    submit() {
      if (this.$refs.cmdForm.validate()) {
        this.loading = true
        // data
        const data = this.command

        if (this.type == 'edit') {
          update(data)
            .then(res => {
              this.command = Object.assign({}, res)
              this.showMessage('บันทึกการแก้ไขข้อมูลเรียบร้อย')
            })
            .catch(err => {
              this.showMessage(err, 'error')
            })
            .finally(() => {
              this.loading = false
            })
          return
        }
        // call api
        store(data)
          .then(res => {
            this.commandId = res.id
            this.command = Object.assign({}, res)
            this.$router.replace({ params: { id: res.id } })
            this.type = 'edit'
            this.showMessage('บันทึกข้อมูลเรียบร้อย เพิ่มข้อมูลจุดตรวจได้เลย')
          })
          .catch(err => {
            this.showMessage(err, 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clear() {
      this.$refs.cmdForm.reset()
    },
    getCommand(id) {
      this.loading = true
      show(id)
        .then(res => {
          console.log(res)
          this.command = Object.assign({}, res.command)
          this.commandId = res.command.id
          // this.$refs.cmdForm.setCommand(this.command)
        })
        .catch(err => {
          console.log(err.response)
          if (err.response.status == 403) {
            this.$router.push({ name: 'Command' })
            this.$toast.error({
              title: 'Error',
              message: 'คุณไม่มีสิทธิ์ในการดูคำสั่งนี้',
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    showApprovalDialog() {
      this.$refs.approvalDialog.open(this.approvalId)
    },

    // utils
    getToday() {
      return new Date().toISOString().substr(0, 10)
    },
    getPreRange(d) {
      const s = this.moment(d)
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD')
      const e = this.moment(d)
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD')
      return [s, e]
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
    async showPdf() {
      const pdfBase64 = await getPdf(this.command.id)
      this.$refs.pdf.open(pdfBase64.pdf)
    },
    convertToBlob(base64) {
      var binary_string = window.atob(base64)
      var len = binary_string.length
      var bytes = new Uint8Array(len)
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    },
  },

  data() {
    return {
      type: 'add',
      valid: true,
      loading: false,
      // snackbar
      showAlert: false,
      snackColor: 'primary',
      message: '',
      // data
      commandId: '',
      approvalId: '',
      command: {
        can: {
          update: false,
          delete: false,
          open: false,
        },
      },
      commandBlank: {
        name: '',
        plan_number: '',
        plan_date: this.getToday(),
        dates: this.getPreRange(this.getToday()),
        objective: '',
        owner_id: '',
        remark: '',
        can: {
          update: true,
          delete: true,
          open: true,
        },
      },
    }
  },
  created() {
    const id = this.$route.params.id

    if (/\d+/.test(id)) {
      // console.log(id)
      this.approvalId = id
      this.type = 'edit'
      this.getCommand(id)
    } else {
      this.type = 'add'
      this.command = Object.assign({}, this.commandBlank)
    }
  },
  watch: {
    'command.plan_date'(v) {
      if (this.type == 'edit') return
      this.command.dates = this.getPreRange(v)
    },
  },
  computed: {},
  components: {
    checkpointList,
    cmdForm,
    pdfDialog,
    approvalDialog,
  },
}
</script>

<style></style>
