import httpFile from './http.file'
import http from './http'
const mainUri = '/api/v2/drunkreport'
const offUri = '/api/v2/officer'

export function list(params) {
  return http({
    url: `${mainUri}`,
    method: 'get',
    params: params,
  })
}

export function store(data) {
  return http({
    url: `${mainUri}`,
    method: 'post',
    data,
  })
}

export function update(data) {
  return http({
    url: `${mainUri}/${data.id}`,
    method: 'patch',
    data,
  })
}

export function destroy(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'delete',
  })
}

export function show(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'get',
  })
}

export function getPdf(id) {
  return http({
    url: `${mainUri}/${id}/pdf`,
    method: 'get',
  })
}

export function getStat(data) {
  return http({
    url: `${mainUri}Stat`,
    method: 'post',
    data,
  })
}

export function activities(id) {
  return http({
    url: `${mainUri}/${id}/activities`,
    method: 'get',
  })
}
